.homeBanner {
    height: 75vh;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    .homeBanner .container {
        position: relative;
        z-index: 2
    }
    .homeBanner_imgwrapper {
        top: 0px;
        width: 100%
    }
    .homeBanner_imgwrapper img {
        height: 100%;
        width: 100%
    }
    .homeBanner__cnt {
        text-align: left;
    }
    .homeBanner__cnt * {
        color: #fff
    }
    .homeBanner__cnt span {
        font-size: 18px
    }
    .homeBanner__cnt h1 {
        font-size: calc(24px + (48 - 24) * ((100vw - 320px) / (1920 - 320)));
        font-family: 'Raleway', sans-serif;
        margin: 0 0 50px 0;
        font-weight: 700;
        padding: 0 230px 0 0;
        text-transform: uppercase;
    }
    .homeBanner__cnt h3 {
        font-size: 22px;
        color: #fff;
        margin-bottom: 60px
    }
    .homeBanner_logo {
        position: relative;
        left: 0;
        right: 0;
        bottom: 30px;
    }
    .service-container {
        max-width: 1600px !important;
        margin: 0 auto;
        width: 100%;
        flex-direction: column;
        display: flex;
    }
    .productFeatures_features {
        border-radius: 10px;
        padding: 30px 30px 30px;
        min-height: 240px;
        margin-bottom: 25px;
        box-shadow: 0 5px 25px -10px rgb(0 0 0 / 25%);
    }
    .productFeatures_features h3 {
        font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
        margin: 0 0 15px;
        color: #000;
        font-weight: 600;
    }
    .productFeatures_features h3 .heading-color {
        color: #2879c9;
        font-size: 20px;
        font-weight: 600;
    }
    .productFeatures_features p {
        font-size: 16px;
        color: #999;
        margin-bottom: 30px;
        line-height: 20px;
        position: relative;
    }
    .btn-info {
        color: #fff;
        background-size: 200% auto;
        border: none;
        background-color: #b0c21f;
        border-radius: 0px;
    }
    .productFeatures_features a.btn-info:hover {
        background-color: #2879c9;
        color: #fff;
        border-color: #2879c9;
    }
    .btn-lg {
        height: 42px;
        font-size: 16px;
    }
    .customerVideo_box {
        min-height: 250px;
        border-radius: 10px;
    }
    .customerVideo_box img {
        object-fit: cover;
        width: 100%;
        height: 260px;
        box-shadow: 0 5px 25px -10px rgb(0 0 0 / 25%);
    }
    .customerVideo_box a {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
        height: 70px;
        display: flex;
        color: #fff;
        // background-color: #2879c9;
        // font-size: 18px;
        // align-items: center;
        // justify-content: center;
        // z-index: 1;
        // border-radius: 50%;
        transition: all .2s;
    }
    .customerVideo_box .video-play-button .icon-play {
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #2879c9;
        z-index: 10;
        top: 50%;
        left: 50%;
        margin-top: -36px;
        margin-left: -36px;
        transition: .4s cubic-bezier(0, 0, .2, 1);
        z-index: 2
    }
    .customerVideo_box .video-play-button .icon-play:before {
        display: none;
    }
    .customerVideo_box .video-play-button .icon-play:after {
        display: block !important;
        border-left: 20px solid #fff !important;
        border-top: 12px solid transparent !important;
        border-bottom: 12px solid transparent !important;
        position: absolute !important;
        content: "";
        width: 28px !important;
        height: 28px;
        z-index: 10;
        top: 50%;
        left: 50%;
        margin-top: -14px;
        margin-left: -6px
    }
    .customerVideo_box:hover .icon-play {
        transform: scale(1.2)
    }
}

.commonSection {
    padding: 90px 0;
    .commonSection_header {
        margin-bottom: 50px;
    }
    .commonSection_title {
        font-family: Raleway;
        font-weight: 600;
        color: #333;
        margin-bottom: 15px;
        font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    h1>span:not(.nocolor):not(.badge),
    h2>span:not(.nocolor):not(.badge),
    h3>span:not(.nocolor):not(.badge),
    h4>span:not(.nocolor):not(.badge),
    h5>span:not(.nocolor):not(.badge),
    h6>span:not(.nocolor):not(.badge) {
        color: #2878c8;
    }
    .blue-border-line {
        height: 4px;
        width: 100px;
        background: linear-gradient(to right, #50e590 50%, #0796c6 120%);
        display: block;
        margin: 0 0 auto;
        margin-bottom: 10px
    }
}

// Commansection
.commonSection {
    padding: 70px 0;
    .commonSection_header {
        margin-bottom: 50px
    }
    .commonSection_title {
        font-family: Raleway;
        font-weight: 600;
        color: #333;
        margin-bottom: 15px;
        font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    h1>span:not(.nocolor):not(.badge),
    h2>span:not(.nocolor):not(.badge),
    h3>span:not(.nocolor):not(.badge),
    h4>span:not(.nocolor):not(.badge),
    h5>span:not(.nocolor):not(.badge),
    h6>span:not(.nocolor):not(.badge) {
        color: #2878c8;
    }
    .blue-border-line {
        height: 4px;
        width: 100px;
        background: linear-gradient(to right, #50e590 50%, #0796c6 120%);
        display: block;
        margin: 0 0 auto;
        margin-bottom: 10px
    }
    .commonSection_header p {
        color: #333;
        line-height: 24px
    }
    .commonSection_header p:last-child {
        margin-bottom: 0
    }
    .commonSection_header p a {
        color: #262d35
    }
    .commonSection_tagline {
        position: relative;
        color: #000;
        margin-bottom: 30px;
        font-size: 16px;
        display: inline-block
    }
    .commonSection_tagline:after {
        position: absolute;
        height: 1px;
        background-color: #666;
        width: 30px;
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        bottom: -10px
    }
    .commonSection_tagline.blue-tagline {
        color: #95dde6
    }
    .commonSection_tagline.blue-tagline:after {
        background-color: #95dde6
    }
    .commonSection_tagline.white-tagline {
        color: #fff
    }
    .commonSection_tagline.white-tagline:after {
        background-color: #fff
    }
    .commonSection_tagline.left:after {
        margin: 0px
    }
    .commonSection_title {
        // font-family: "Montserrat-Bold";
        // line-height: 48px;
        color: #000;
        margin-bottom: 15px;
        font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)))
    }
    .commonSection_title span {
        font-family: "Raleway"
    }
    .commonSection--column__inner {
        padding: 90px 0
    }
    .commpageCnt p {
        color: #333
    }
}

@media (max-width: 1440px) {
    .commonSection {
        padding: 50px 0
    }
    .commonSection--column__inner {
        padding: 50px 0
    }
}

@media (max-width: 1399px) {
    .commonSection_title {
        line-height: 40px
    }
}

@media (max-width: 1199.98px) {
    .commonSection {
        padding: 50px 0
    }
    .commonSection_header {
        margin-bottom: 30px
    }
    .commonSection_title {
        line-height: 37px
    }
}

@media (max-width: 991.98px) {
    .commonSection {
        padding: 30px 0
    }
    .commonSection_title {
        line-height: 33px
    }
}

@media (max-width: 767.98px) {
    .commonSection {
        padding: 25px 0
    }
    .commonSection_header {
        margin-bottom: 20px
    }
    .commonSection_header p {
        line-height: 23px
    }
    .commonSection_tagline {
        margin-bottom: 25px;
        font-size: 14px
    }
    .commonSection_title {
        margin-bottom: 10px
    }
}

@media (max-width: 575.98px) {
    .commonSection_title {
        line-height: 29px
    }
}

// offering section css 
.startupsEnterprise {
    .row {
        margin: 0 -10px
    }
    .row>[class*='col-'] {
        padding: 0 10px
    }
    .startupsEnterprise_box {
        min-height: 190px;
        border-radius: 10px;
        margin-bottom: 20px;
    }
    .startupsEnterprise_box:hover .startupsEnterprise_desc {
        bottom: 0
    }
    .startupsEnterprise_box img {
        width: 100%
    }
    .startupsEnterprise_desc {
        top: 0;
        z-index: 1;
        padding: 30px
    }
    .startupsEnterprise_desc h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5;
        font-family: Raleway, sans-serif !important;
        margin: 0 0 40px 0;
        color: #444;
    }
    .startupsEnterprise_desc p {
        font-size: 16px;
        font-family: "Montserrat-Regular";
        margin-bottom: 30px
    }
    .link {
        position: relative;
    }
    .link {
        align-items: center;
        display: flex;
        outline: 0;
        z-index: 99;
    }
    .link__icon {
        position: relative;
    }
    .link__text {
        color: #2879c9;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        padding-right: 16px;
    }
    .link__icon--big {
        height: 40px;
        width: 40px;
    }
    .link__icon:before {
        left: 50%;
        top: 50%;
        content: '';
    }
    .link__icon:after {
        left: 50%;
        top: 50%;
        content: '';
    }
    .link__icon,
    .link__icon:before {
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 0px 6px #00000029;
    }
    .link__icon--big:after {
        height: 10px;
        margin-left: -3px;
        width: 10px;
    }
    .link__icon:after {
        border-left: 1px solid #2879c9;
        border-top: 1px solid #2879c9;
        margin-left: -1px;
        position: absolute;
        transform: translate(-50%, -50%) rotate(135deg);
    }
    .link:hover .link__icon:before {
        transform: translate3d(-50%, -50%, 0) scale(1);
        transition: transform .45s cubic-bezier(.6, .01, 0, 1.38)
    }
    @media (max-width: 1599px) {
        .startupsEnterprise_desc h3 {
            font-size: 24px
        }
    }
    @media (max-width: 1399px) {
        .startupsEnterprise_desc h3 {
            font-size: 24px;
            line-height: 28px;
        }
        .startupsEnterprise_desc p {
            margin-bottom: 25px
        }
    }
    @media (max-width: 1199.98px) {
        .startupsEnterprise_box {
            min-height: auto;
            max-height: 400px
        }
        .startupsEnterprise_desc {
            padding: 20px;
            /* bottom: -70px */
        }
        .startupsEnterprise_desc h3 {
            font-size: 24px
        }
        .startupsEnterprise_desc p {
            font-size: 15px
        }
    }
    @media (max-width: 991.98px) {
        .startupsEnterprise .row {
            margin: 0 -7px
        }
        .startupsEnterprise .row>[class*='col-'] {
            padding: 0 7px
        }
        .startupsEnterprise_box {
            margin-bottom: 15px;
            max-height: 350px;
            /* min-height: 150px; */
        }
        .startupsEnterprise_box img {
            height: 150px;
        }
        .startupsEnterprise_desc h3 {
            font-size: 18px;
            margin: 0 0 15px 0;
        }
    }
    @media (max-width: 575.98px) {
        .startupsEnterprise_box {
            max-height: 300px
        }
        .startupsEnterprise_box img {
            height: 140px;
        }
        .startupsEnterprise_desc p {
            font-size: 14px
        }
    }
}

.industriesWeserve {
    background-color: #f6f6f6;
    .works {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        grid-gap: 24px
    }
    .works .work-a {
        max-width: 100%;
        width: 100%
    }
    .works .work {
        position: relative;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 25px 0 25px 32px;
        overflow: hidden;
        cursor: pointer;
        height: 235px;
        width: 100%
    }
    .works .nda-content {
        position: relative;
        z-index: 1
    }
    .works .work {
        display: inline-block
    }
    .works .work:hover {
        box-shadow: 0 6px 20px 0 rgba(23, 39, 53, .08)
    }
    .works .work.liligo {
        box-shadow: 0 0 20px rgb(0 0 0 / 14%);
        background-color: #ffffff;
    }
    .works .work.societe {
        background-color: #000
    }
    .works .work.un {
        background-color: #5b92e5
    }
    .works .work.hbo {
        background-image: radial-gradient(circle at 77% 58%, hsla(0, 0%, 100%, .1), transparent), linear-gradient(#282a28, #282a28)
    }
    .works .work h3 {
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        line-height: 28px;
        font-style: normal;
        font-stretch: normal;
        max-width: 100%;
        position: relative;
        z-index: 1;
        margin-bottom: 40px;
        font-weight: 700;
        line-height: 1.5;
        font-family: Raleway, sans-serif !important;
        color: #444;
    }
    .works .work .case-study-logo {
        max-height: 100px
    }
    .works .work .image {
        transform-origin: 100%;
        height: 100%;
        width: 100%
    }
    .works .work .image picture {
        height: 100%;
        width: 100%;
        float: right
    }
    .works .work .image img {
        max-width: 100%;
        width: 100%
    }
    .works .work:hover {
        text-decoration: none
    }
    .works .work.un .image img {
        width: auto;
        height: 54%;
        position: absolute;
        bottom: 0;
        right: -20%
    }
    .works .work.hbo .image img {
        width: auto;
        height: 57%;
        position: absolute;
        bottom: -4px;
        right: -11%
    }
    .works .work.societe .image img {
        width: auto;
        height: 54%;
        position: absolute;
        bottom: -2px;
        right: -22%
    }
    .works .work.liligo .image img {
        width: auto;
        position: absolute;
        bottom: 0;
        right: 0
    }
    .link {
        position: relative;
    }
    .link {
        align-items: center;
        display: flex;
        outline: 0;
        z-index: 99;
    }
    .link__icon {
        position: relative;
    }
    .link__text {
        color: #2879c9;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        padding-right: 16px;
    }
    .link__icon--big {
        height: 40px;
        width: 40px;
    }
    .link__icon:before {
        left: 50%;
        top: 50%;
        content: '';
    }
    .link__icon:after {
        left: 50%;
        top: 50%;
        content: '';
    }
    .link__icon,
    .link__icon:before {
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 0px 6px #00000029;
    }
    .link__icon--big:after {
        height: 10px;
        margin-left: -3px;
        width: 10px;
    }
    .link__icon:after {
        border-left: 1px solid #2879c9;
        border-top: 1px solid #2879c9;
        margin-left: -1px;
        position: absolute;
        transform: translate(-50%, -50%) rotate(135deg);
    }
    .link:hover .link__icon:before {
        transform: translate3d(-50%, -50%, 0) scale(1);
        transition: transform .45s cubic-bezier(.6, .01, 0, 1.38)
    }
    @media (max-width: 991.98px) {
        .works .work.liligo .image img {
            width: auto;
            height: auto;
            position: absolute;
            bottom: 0;
            right: 0;
        }
        .works .work.liligo .image img {
            background-size: 250px;
            background-position: right;
            opacity: .2;
        }
    }
    @media (max-width: 767.98px) {
        .works .work.liligo .image img {
            opacity: 0.5;
        }
        .works .work {
            height: 180px;
            width: 100%;
        }
        .block-space- {
            margin-top: 20px;
        }
        .block-space- h3 {
            margin-bottom: 20px !important;
        }
    }
    @media (max-width: 600px) {
        .container {
            padding: 0 24px
        }
        /* h2 {
            margin: 40px 0;
            font-size: 30px;
            line-height: 1.33;
            letter-spacing: .3px;
            font-style: normal;
            font-stretch: normal
        } */
        .ppc h2 {
            margin: 0 0 45px
        }
        .mockup-text h3 {
            padding: 26px 40px 24px;
            margin-bottom: 0;
            font-size: 22px;
            line-height: 1.27;
            text-align: center;
            color: #172735;
            font-weight: 700
        }
        .works {
            grid-gap: 16px
        }
        .works .work {
            height: 180px;
            padding: 24px
        }
        .works .work-a:nth-child(3) {
            -ms-grid-row: 4;
            grid-row: 4
        }
        .works .work h3 {
            margin: 0;
            // font-size: 20px;
            font-weight: 700;
            line-height: 1.2
        }
        .works .work .case-study-logo {
            position: absolute;
            bottom: 24px;
            left: 24px;
            max-width: 140px;
            z-index: 99;
        }
        .works .case-study-logo img {
            width: 100%
        }
        .works .work .image picture {
            width: 80%
        }
        .mockup-text {
            margin-top: -170px
        }
        .mockup-text h2 {
            font-size: 22px;
            font-weight: 700;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.27;
            color: #172735;
            max-width: 200px;
            margin: 2px auto 24px
        }
        .btn.btn-green {
            width: 175px;
            margin-bottom: 80px
        }
        .works .work.liligo .image img {
            width: auto;
            height: 75%;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    @media (max-width: 499px) {
        .works .work {
            height: 175px;
            width: 100%;
        }
    }
    @media (max-width: 330px) {
        .works .case-study-logo img {
            width: 80%
        }
    }
    @media (min-width: 768px) {
        .works {
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr
        }
        .works .work.un .image img {
            width: auto;
            height: 66%;
            position: absolute;
            bottom: 0;
            right: 0
        }
        .works .work.hbo .image img {
            width: auto;
            height: 76%;
            position: absolute;
            bottom: -4px;
            right: -4px
        }
        .works .work.societe .image img {
            width: auto;
            height: 76%;
            position: absolute;
            bottom: -2px;
            right: -4px
        }
        /* .works .work.liligo .image img {
            width: auto;
            height: 100%;
            position: absolute;
            bottom: 0;
            right: 0
        } */
    }
    @media (min-width: 768px) and (max-width:1200px) {
        .mockup-text a {
            margin-bottom: 0
        }
    }
    @media (min-width: 1200px) {
        .works {
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr
        }
    }
}

.secApp {
    .rowApp [class^="col-"] {
        padding: 0 10px;
    }
    .cardApp {
        position: relative;
        border-radius: 10px;
        height: 460px;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .cardApp_imgWrapper {
        min-height: 100%;
        position: relative
    }
    .cardApp_imgWrapper:after {
        content: "";
        left: 0;
        right: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, transparent, black);
        transition: all ease-in-out 0.3s;
        background-color: transparent
    }
    .cardApp_imgWrapper img {
        width: 100%;
        height: 100%
    }
    .cardApp_data {
        position: absolute;
        bottom: 0;
        width: 100%;
        transform: translateY(120px);
        transition: transform 0.5s;
        color: #fff;
        padding: 25px
    }
    .cardApp_number {
        margin-bottom: 15px;
        font-size: 60px;
        // font-family: "Montserrat-Bold";
        line-height: 60px
    }
    .cardApp_title {
        transition: all .4s ease-in-out;
        font-size: 24px;
        font-weight: 600;
        color: #2879c9;
        margin-bottom: 25px
    }
    .cardApp_text {
        font-size: 18px;
        max-height: 130px;
        min-height: 130px;
        overflow: hidden;
        margin-right: -10px;
        padding-right: 10px
    }
    .cardApp:hover {
        box-shadow: 0 5px 50px rgba(0, 0, 0, 0.05)
    }
    .cardApp:hover .cardApp_data {
        transform: translateY(0);
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    .cardApp:hover .cardApp_number {
        color: #ffffff
    }
    .cardApp:hover .cardApp_title {
        color: #ffffff;
        margin-bottom: 15px
    }
    .cardApp:hover .cardApp_text {
        color: #ffffff;
        max-height: 150px;
        min-height: initial
    }
    .btn_part {
        min-width: 135px !important;
        max-width: 135px;
        height: 48px;
        line-height: 48px;
        border-radius: 0 !important;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        padding: 0 10px;
        color: #000;
        text-transform: none;
        border: none;
        background: #b0c21f;
        cursor: pointer;
        display: inline-block;
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease 0s;
        z-index: 1;
    }
    @media (max-width: 1439px) {
        .cardApp {
            height: 423px
        }
        .cardApp_data {
            padding: 15px;
            transform: translateY(180px)
        }
        .cardApp_number {
            margin-bottom: 10px;
            font-size: 45px;
            line-height: 45px
        }
        .cardApp_title {
            margin-bottom: 15px;
        }
        .cardApp:hover .cardApp__title {
            margin-bottom: 10px
        }
    }
    @media (max-width: 1199.98px) {
        .cardRead_bottom {
            min-height: calc(100% - 114px)
        }
    }
    @media (max-width: 991.98px) {
        .rowApp {
            margin-left: -5px;
            margin-right: -5px
        }
        .rowApp [class^="col-"] {
            padding: 0 5px
        }
        .cardApp {
            height: 343px;
            margin-bottom: 10px;
            border-radius: 8px
        }
        .cardApp_number {
            margin-bottom: 8px;
            font-size: 36px;
            line-height: 36px
        }
        .cardApp_title {
            margin-bottom: 10px;
            font-size: 14px
        }
        .cardApp_data {
            padding: 10px;
            transform: translateY(174px)
        }
        .cardApp:hover .cardApp_text {
            max-height: 230px;
            overflow: auto
        }
    }
    @media (max-width: 767.98px) {
        .cardApp {
            margin: 0 auto 10px;
            max-width: 250px;
        }
    }
    @media (max-width: 659px) {
        .rowApp {
            margin-left: auto;
            margin-right: auto;
            max-width: 468px
        }
        .rowApp [class^="col-"] {
            flex: 0 0 50%;
            max-width: 50%
        }
        .rowApp [class^="col-"].columnLast {
            flex: 0 0 100%;
            max-width: 100%
        }
        .cardApp__text {
            font-size: 13px
        }
    }
    @media (max-width: 499px) {
        .cardApp_text {
            font-size: 14px;
        }
        .rowApp {
            flex-wrap: nowrap;
            overflow-y: auto;
            margin-bottom: 10px
        }
        .rowApp [class^="col-"] {
            flex: 0 0 234px;
            max-width: 234px
        }
        .rowApp [class^="col-"].columnLast {
            display: none
        }
    }
}

// video section css
.what-client-see {
    background-color: #f6f6f6;
    .grd-text {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
        grid-gap: 48px
    }
    .grd-text .brick {
        padding-left: 15px
    }
    .grd {
        display: grid;
        grid-gap: 40px;
        overflow: hidden;
        grid-template-columns: repeat(auto-fit, minmax(auto, 380px));
        margin-top: 48px;
        justify-content: center;
    }
    .tagline {
        font-weight: 600;
        font-size: 24px
    }
    .mcom-logo {
        // background-image: url(images/wk-sprite-global.png?v=1.8);
        background-repeat: no-repeat;
        width: 251px;
        height: 40px;
        display: block;
        background-position: 0 -56px
    }
    .wk-video {
        position: relative;
        border-radius: 16px;
        background-color: #b2b5c4;
        overflow: hidden;
        height: 400px;
        width: 100%;
        cursor: pointer;
    }
    .wk-video:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.349);
        border-radius: 10px;
        height: 100%;
        width: 100%;
    }
    .wk-video .video-name {
        z-index: 2;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        position: absolute;
        bottom: 0;
        padding: 20px 40px 20px 20px
    }
    .wk-video .video-poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
    .wk-video .video-poster img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover
    }
    .wk-video .video-poster .icon-play {
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #2879c9;
        z-index: 10;
        top: 50%;
        left: 50%;
        margin-top: -36px;
        margin-left: -36px;
        transition: .4s cubic-bezier(0, 0, .2, 1);
        z-index: 2
    }
    .wk-video .video-poster .icon-play:before {
        display: none;
    }
    .wk-video .video-poster .icon-play:after {
        display: block;
        border-left: 20px solid #fff;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        position: absolute;
        content: "";
        width: 28px;
        height: 28px;
        z-index: 10;
        top: 50%;
        left: 50%;
        margin-top: -14px;
        margin-left: -6px
    }
    .wk-video:hover .icon-play {
        transform: scale(1.2)
    }
    @media (max-width:1023px) {
        .grd-text {
            grid-template-columns: auto;
            grid-gap: 10px
        }
        .grd {
            grid-template-columns: repeat(auto-fit, minmax(auto, 330px));
            grid-gap: 40px;
            margin-top: 48px
        }
        .grd .brick {
            width: 100%;
            grid-column: 1/4
        }
    }
    @media (max-width:768px) {
        .grd .brick {
            grid-column: 1/3
        }
        section.resources .one,
        section.resources .two {
            height: 300px;
            margin-bottom: 20px;
        }
    }
    @media (max-width:525px) {
        .grd .brick {
            grid-column: 1/2
        }
    }
}

section.resources .one {
    height: 515px;
    width: 100%;
}

section.resources .two,
section.resources .two img.video {
    height: 245px;
    width: 100%;
}

// why thinkitive
.company-since {
    .headingRow {
        margin: 0 0 3rem;
        /* text-align: center; */
    }
    .headingRow P {
        font-size: 18px;
        color: #4d5156;
    }
    .company-text,
    .counter-number {
        padding: 0 0 0 2rem;
    }
    .empower_listing-think {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .empower_listing-think li {
        position: relative;
        padding-left: 30px;
        color: #4d5156;
        margin-bottom: 15px;
        font-size: 16px;
    }
    .empower_listing-think li:after {
        content: "";
        position: absolute;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: #B0CB1F;
        left: 0;
        top: 7px;
    }
    .company-text p {
        font-size: 16px;
        color: #4d5156;
        margin: 0 0 1rem;
    }
    .list-unstyled {
        padding-left: 30px;
        list-style: none;
    }
    .counter-number ul {
        justify-content: space-between;
        flex-wrap: wrap
    }
    .counter-number ul {
        display: flex;
    }
    .counter-number ul li {
        flex-basis: 40%;
        text-transform: uppercase;
        color: #4d5156;
        font-weight: 700;
        margin: 3rem 0 0;
        list-style: none;
    }
    .counter-number ul li span {
        font-size: 48px;
        color: #2879c9;
        font-weight: 700;
        display: block
    }
    @media (max-width:1600px) {
        .counter-number ul li span {
            font-size: 48px;
        }
    }
    @media (max-width: 1199.98px) {
        .counter-number ul li span {
            font-size: 32px;
        }
    }
}

@media (max-width: 1699px) {
    .homeBanner__cnt h1 {
        margin: 20px 0
    }
    .homeBanner__cnt h3 {
        margin-bottom: 40px
    }
    .productFeatures_features h3 {
        margin-bottom: 20px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1499px) {
    .homeBanner_logo {
        bottom: 10px
    }
}

@media (max-width: 1199.98px) {
    .homeBanner {
        height: auto;
        padding: 50px 0 20px;
    }
    .homeBanner__cnt h1 {
        /* padding: 0 186px; */
        line-height: 40px
    }
    .homeBanner_logo {
        position: static;
        margin-top: 50px
    }
    .homeBanner__cnt h3 {
        margin-bottom: 0;
    }
    .productFeatures_features {
        border-radius: 10px;
        padding: 15px 15px 15px;
        min-height: 200px;
        margin-bottom: 25px;
    }
    .productFeatures_features p {
        margin-bottom: 20px;
    }
    .customerVideo_box {
        min-height: 230px;
        border-radius: 10px;
    }
    .customerVideo_box img {
        object-fit: cover;
        width: 100%;
        height: 230px;
    }
}

@media (max-width: 991.98px) {
    .homeBanner_imgwrapper img {
        object-fit: cover;
    }
    .homeBanner__cnt h1 {
        /* padding: 0 140px; */
        line-height: 36px
    }
    .homeBanner__cnt h3 {
        font-size: 18px;
        margin-bottom: 15px
    }
}

@media (max-width: 767.98px) {
    .homeBanner {
        padding-top: 10px;
        height: 320px
    }
    .homeBanner__cnt h1 {
        line-height: 30px;
        padding: 0 !important;
        margin-bottom: 20px !important;
    }
    .homeBanner__cnt span {
        font-size: 13px
    }
    .homeBanner__cnt h3 {
        font-size: 18px !important;
        margin-bottom: 15px
    }
    .homeBanner_logo {
        margin-top: 25px
    }
    section.resources .one,
    section.resources .two {
        height: 300px;
        margin-bottom: 20px;
    }
    #top-bar,
    #top-social {
        display: none;
    }
    .company-since .company-text,
    .company-since .counter-number {
        padding: 0;
    }
    .company-since .company-text p {
        margin-top: 20px;
        font-size: 16px;
    }
    .company-since .empower_listing-think li {
        color: #4d5156;
        margin-bottom: 15px;
        font-size: 16px;
    }
    .company-since .yel-button {
        margin: 0 auto;
        text-align: center;
    }
}

@media (max-width: 575.98px) {
    .homeBanner {
        height: 330px
    }
    .homeBanner .container {
        z-index: 1
    }
    .homeBanner__cnt h1 {
        line-height: 32px !important;
        padding: 0 !important;
        margin-bottom: 20px;
    }
    .homeBanner__cnt h3 {
        margin-bottom: 30px !important;
    }
    .link-button {
        margin: 0 !important;
    }
}